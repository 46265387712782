import {inject, Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {filter, map, take} from 'rxjs/operators';
import {SessionQuery} from '../../state/session.query';
import {SessionService} from '../../state/session.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard {
  private readonly sessionService = inject(SessionService);
  private readonly sessionQuery = inject(SessionQuery);

  // Only for usage with AzureAD
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.sessionService.getUser().subscribe();

    return this.sessionQuery.user$.pipe(
      map(user => !!user),
      take(1),
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.sessionService.getUser().subscribe();

    return this.sessionQuery.user$.pipe(
      filter(user => !!user),
      map(user => !!user),
      take(1),
    );
  }
}
