import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree} from '@angular/router';
import {MsalGuard} from '@azure/msal-angular';
import {AuthGuard} from '../auth/auth.guard';

@Injectable({
  providedIn: 'root'
})
export class AllGuard {

  constructor(
    private msalGuard: MsalGuard,
    private authGuard: AuthGuard) {
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    const msalGuardResult = await this.msalGuard.canActivate(route, state).toPromise();
    const authGuardResult = await this.authGuard.canActivate(route, state);

    return !!(msalGuardResult && authGuardResult);
  }

  async canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    const msalGuardResult = await this.msalGuard.canActivateChild(route, state).toPromise();

    let authGuardResult = null;
    if (msalGuardResult) {
      authGuardResult = await this.authGuard.canActivateChild(route, state).toPromise();
    }

    return !!(msalGuardResult && authGuardResult);
  }
}
