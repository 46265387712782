import {HomeService} from '@/home/state/home.service';
import {SharedConstants} from '@/shared/constants';
import {UiService} from '@/shared/state/ui.service';
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree} from '@angular/router';
import {of} from 'rxjs';
import {PermissionsService} from '../../state/permissions/permissions.service';
import {SessionService} from '../../state/session.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionGuard {
  navigationModes = SharedConstants.NAVIGATION_MODES;

  constructor(
    private sessionService: SessionService,
    private uiService: UiService,
    private permissionsService: PermissionsService,
    private homeService: HomeService,
  ) {
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    let result: boolean | UrlTree | PromiseLike<boolean | UrlTree>;

    const permissions = route.data['permissions'];

    const module = route.data['module'] as string;
    const navigationModeByModule = this.navigationModes.find(navigationMode => navigationMode.module === module);
    if (permissions) {
      result = await this.permissionsService.checkIfUserHasOneOrMorePermissionTo(permissions);
      if (result) {
        this.uiService.updateNavigationMode(navigationModeByModule); // TODO Check frontend permission for module
      } else {
        this.homeService.navigateToHome();
      }
    } else {
      result = of(true).toPromise();
      this.uiService.updateNavigationMode(navigationModeByModule); // TODO Check frontend permission for module
    }

    return result;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    let result: boolean | UrlTree | PromiseLike<boolean | UrlTree>;

    const permissions = route.data['permissions'];

    if (permissions) {
      result = await this.permissionsService.checkIfUserHasOneOrMorePermissionTo(permissions);
    } else {
      result = of(true).toPromise();
    }

    return result;
  }
}
