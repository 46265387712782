import {SleekplanService} from '@/core/sleekplan/state/sleekplan.service';
import {Component, inject, OnDestroy, OnInit} from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  private readonly sleekplanService = inject(SleekplanService);

  // isIframe is necessary to prevent routing while msal login/token fetching is in process
  isIframe = false;

  ngOnInit() {
    this.isIframe = window !== window.parent && !window.opener; // Remove this line to use Angular Universal

    this.sleekplanService.initializeSleekplan();
  }

  ngOnDestroy() {
    this.sleekplanService.destroySleekplan();
  }
}
